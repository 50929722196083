@keyframes verify-email-dots {
  0% {
    transform: translateY(0px);
  }
  
  25%{
    transform: translateY(15px);
  }
  
  50% {
    transform: translateY(0px);
  }

  75%{
    transform: translateY(-15px);
  }
  
  100% {
    transform: translateY(0px);
  }
}
